import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Helmet } from 'react-helmet'
import favicon from '../images/favicon.png'

const links = [
  {
    text: "eurorackDIY YouTube Channel",
    url: "https://youtube.com/c/eurorackDIY",
  },
  {
    text: "Unbuffered Mult Series",
    url: "https://www.youtube.com/playlist?list=PLJp3U60JASHlc7BVpB8VUkJoczbGfWs8p",
  },
  {
    text: "eurorackDIY on Instagram",
    url: "https://instagram.com/eurorackDIY",
  },
  {
    text: "eurorackDIY on GitHub",
    url: "https://github.com/eurorackDIY",
  },
]

const LinksPage = () => {
  return (
    <>
      <Helmet>
        <title>eurorackDIY Links</title>
        <link rel="icon" href={favicon} />
        <body class="bg-ediy" />
      </Helmet>

      <main class="container mx-auto text-center w-64 md:w-192">
        <div class="pt-4 md:p-10">
          <h1 class="items-center text-xl md:text-4xl font-bold text-white">eurorackDIY Links</h1>
          <StaticImage className="w-60 md:w-72 m-2 md:m-8 inline-flex items-center" src="../images/avatar-2.jpg" alt="Site Logo"/>
        </div>
        <ul>
          {links.map(link => (
            <li key={link.url}>
              <div class="w-full inline-flex">
              <OutboundLink
                class="w-full m-2 p-3 rounded-lg text-sm md:text-lg text-ediy-100 bg-ediy-700 rounded-lg hover:bg-ediy-800"
                href={link.url}
              >
                {link.text}
              </OutboundLink>
              </div>
            </li>
          ))}

          <li>
            <div class="w-full inline-flex">
            <Link
              class="w-full m-2 p-3 rounded-lg text-sm md:text-lg text-ediy-100 bg-ediy-700 rounded-lg hover:bg-ediy-800"
              href="/blog"
            >
              eurorackDIY Blog
            </Link>
            </div>
          </li>
        </ul>
      </main>
    </>
  )
}

export default LinksPage
